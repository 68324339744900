<template>
  <div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      fullscreen
      center
      :close-on-click-modal="false"
    >
      <div style="border: 1px solid #ccc; margin-top: 10px">
        <!-- 工具栏 -->
        <Toolbar
          style="border-bottom: 1px solid #ccc"
          :editor="editor"
          :default-config="toolbarConfig"
        />
        <!-- 编辑器 -->
        <Editor
          v-if="dialogVisible"
          v-model="newHtml"
          style="height: 600px; overflow-y: hidden"
          :default-config="editorConfig"
          @onCreated="onCreated"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="$emit('input',false)">取消</el-button>
        <el-button type="primary" @click="handleWangSubmit">保存</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { saveGenerateAgreementCode } from '@/api/adjust-price'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
  name: 'EditorVue',
  components: { Editor, Toolbar },
  props: {
    html: {
      type: String,
      default: ''
    },
    readOnlys: { // 只读
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    value: {
      type: Boolean,
      default: false
    },
    params: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      saveHtml: '',
      editor: null,
      toolbarConfig: {
        /* 显示哪些菜单，如何排序、分组 */
        toolbarKeys: [
          'headerSelect',
          // '|',
          'bold',
          'underline',
          'italic',
          'color',
          'bgColor',
          // '|',
          'indent', // 增加缩进
          'delIndent', // 减少缩进
          'justifyLeft', // 左对齐
          'justifyRight', // 右对齐
          'justifyCenter', // 居中
          'justifyJustify', // 两端对齐
          // '|',
          'fontSize',
          'fontFamily',
          'lineHeight',
          // '|',
          'bulletedList',
          'numberedList',
          'todo',
          'insertLink',
          'insertTable',
          // '|',
          'codeBlock',
          'divider',
          'uploadImage',
          'undo',
          'redo'
        ]
        // excludeKeys: [ ], /* 隐藏哪些菜单 */
      },
      editorConfig: {
        placeholder: '请输入内容',
        // autoFocus: false,
        // readOnly: true, // 只读、不可编辑
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
          // 配置上传图片
          uploadImage: {
            customUpload: this.uploaadImg
          }
        }
      }
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    newHtml: {
      get() {
        return this.html
      },
      set(val) {
        // this.$emit('getNewHtml', val)
        this.saveHtml = val
      }
    }
  },
  watch: {

  },
  mounted() {

  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁 editor
  },
  methods: {
    async handleWangSubmit() {
      const { purchaseOrderContractCode, changePriceBillCode, purchaseOrderCode } = this.params
      const params = Object.assign({ purchaseOrderContractCode, changePriceBillCode, purchaseOrderCode,
        fileRichText: this.saveHtml, changePriceAgreementCode: this.params.changePriceAgreementCode })
      const { datas = '' } = await saveGenerateAgreementCode(params)
      this.$emit('saveWangEdit', { html: this.saveHtml, idx: this.index, changePriceAgreementCode: datas })
      this.$emit('input', false)
    },
    uploaadImg(file, insertFn) {
      this.$emit('uploadImg', file, insertFn)
    },
    onCreated(editor) {
      this.editor = Object.seal(editor)
    }
  }
}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
