<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleTabClick">
      <el-tab-pane label="整单显示" name="first">
        <QueryForm v-model="queryForm" :query-btn-loading="tableLoading" @query="handleQuery()" @reset="handleQueryFormReset">
          <el-form-item label="调价单号">
            <el-input v-model="queryForm.changePriceBillCode" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
          <el-form-item label="采购订单号">
            <el-input v-model="queryForm.purchaseOrderCode" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
          <el-form-item label="供应商">
            <Select
              v-model="queryForm.vendorIdList"
              api-key="vendorList"
              clearable
              multiple
            />
          </el-form-item>
          <el-form-item label="状态">
            <Select
              v-model="queryForm.billStatusList"
              :select-options="_getAllCommodityDict('CHANGE_PRICE_BILL_STATE')"
              :configuration="optionsConfig"
              clearable
              multiple
            />
          </el-form-item>
          <el-form-item label="成本工程师">
            <Select
              v-model="queryForm.costEngineerName"
              api-key="newUserList"
              clearable
            />
          </el-form-item>
          <el-form-item label="调价发起时间">
            <el-date-picker
              v-model="queryForm.value"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            />
          </el-form-item>
        </QueryForm>
      </el-tab-pane>
      <el-tab-pane label="明细显示" name="second">
        <QueryForm v-model="queryDetailForm" :query-btn-loading="tableLoading" @query="handleQuery()" @reset="handleQueryFormReset">
          <el-form-item label="调价单号">
            <el-input v-model="queryDetailForm.changePriceBillCode" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
          <el-form-item label="采购订单号">
            <el-input v-model="queryDetailForm.purchaseOrderCode" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
          <el-form-item label="Style">
            <Select
              v-model="queryDetailForm.styleList"
              api-key="styleList"
              clearable
              @responseData="val=>styleList=val"
            />
          </el-form-item>
          <el-form-item label="Color">
            <Select
              v-model="queryDetailForm.colorList"
              api-key="colorList"
              :params="{ styleId : queryDetailForm.styleList }"
              ganged
              :ganged-prompt="$t('page.selectPlaceholder') + ' Style'"
              @responseData="val=>colorList=val"
            />
          </el-form-item>
          <el-form-item label="Size">
            <Select
              v-model="queryDetailForm.sizeList"
              api-key="sizeList"
              :params="{ styleId : queryDetailForm.styleList}"
              ganged
              multiple
              :ganged-prompt="$t('page.selectPlaceholder') + ' Style'"
              @responseData="val=>sizeList=val"
            />
          </el-form-item>
          <el-form-item label="供应商">
            <Select
              v-model="queryDetailForm.vendorIdList"
              api-key="vendorList"
              clearable
              multiple
            />
          </el-form-item>
          <el-form-item label="币种">
            <Select
              v-model="queryDetailForm.currencyCodeList"
              :select-options="_getAllCommodityDict('CURRENCY_TYPE')"
              :configuration="optionsConfig"
              clearable
              multiple
            />
          </el-form-item>
          <el-form-item label="状态">
            <Select
              v-model="queryDetailForm.billStatusList"
              :select-options="_getAllCommodityDict('CHANGE_PRICE_BILL_STATE')"
              :configuration="optionsConfig"
              clearable
              multiple
            />
          </el-form-item>
        </QueryForm>
      </el-tab-pane>
      <el-row :gutter="10" class="mt-3 mb-3">
        <el-button v-permission="'add'" type="primary" class="mr-2" @click="handleAdd({},'add')">新增调价</el-button>
        <ImportFile
          ref="uploadForm"
          v-model="importDialogVisible"
          v-permission="'import'"
          :upload-file="uploadFile"
          :auto-upload="true"
          :details-data="importDatas"
          style="display: inline-block!important;"
          :import-details-columns="importAdjustDetailsColumns"
          :import-submit-loading="importSubmitLoading"
          import-name="批量导入"
          @getSelectionChange="val=>selectImportData=val"
          @submitDetailDatas="submitDetailDatas"
          @getFile="({file:val})=>file=val"
        />
        <el-button type="primary" class="mr-2 ml-2" :loading="exportLoading" @click="handleExport">模板下载</el-button>
        <ImportButton v-permission="'export'" :export-key="activeName==='first'?'change_price_bill_page':'change_price_bill_page_detail'" :params="exportParams" :btn-type="'primary'" />
        <el-button v-permission="'edit'" type="primary" @click="handleContract">编辑调价协议</el-button>

      </el-row>
      <Table
        ref="multipleTable"
        :table-data="activeName==='first'?wholeTable:detailTable"
        :columns="activeName==='first'?wholeColumns:detailColumns"
        :page-obj="activeName==='first'?wholePager:detailPager"
        :check="true"
        :loading="tableLoading"
        @handleSelectionChange="val => {multipleSelectionTable=val}"
      >
        <el-table-column slot="adjustpo" label="调价单号" align="center" width="170">
          <template slot-scope="scope">
            <el-button type="text" @click="handleAdd(scope.row,'view')">{{ scope.row.changePriceBillCode }}
            </el-button>
          </template>
        </el-table-column>
        <!-- 多个换行展示 -->
        <el-table-column slot="po" label="采购订单号" align="center" width="170">
          <template slot-scope="scope">
            <div v-for="(item,index) in scope.row.purchaseOrderCode" :key="index">{{ item }}</div>
          </template>
        </el-table-column>
        <el-table-column slot="changePriceAgreementCode" label="变更协议编号" align="center" width="200">
          <template slot-scope="scope">
            <div v-for="(item,index) in scope.row.changePriceAgreementCode" :key="index">{{ item }}</div>
          </template>
        </el-table-column>
        <el-table-column slot="operate" label="操作" align="center" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button v-if="['1','3'].includes(scope.row.billStatus+'')" type="text" @click="handleAdd(scope.row,'edit')">修改</el-button>
            <el-button v-if="['1','3'].includes(scope.row.billStatus+'')" type="text" @click="handleDelete(scope.row)">作废</el-button>
          </template>
        </el-table-column>
      </Table>
      <Paging :pager="activeName==='first'?wholePager:detailPager" end @pagination="pagerUpdate" />
      <EditContract v-model="contractDialogVisible" :change-price-bill-code="multipleSelectionTable[0]&&multipleSelectionTable[0].changePriceBillCode" @refresh="handleQuery" />
    </el-tabs>

  </div>
</template>

<script>
import ImportButton from '@/components/ExportFile'
import Table from '@/components/Table'
import QueryForm from '@/components/QueryForm'
import Select from '@/components/Selection'
import Paging from '@/components/Pagination'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { wholeColumns, detailColumns, importAdjustDetailsColumns } from '@/constant/tablecolumns'
import ImportFile from '@/components/ImportFile'
import { cancel, priceBillDetailPage, priceBillPage, changePriceBillImportView, changePriceBillImport } from '@/api/adjust-price'
import { findDownloadUrl } from '@/api/scm-api'
import { downloads } from '@/utils'
import { omit } from 'lodash'
import EditContract from './add/components/EditContract'
export default {
  components: {
    QueryForm,
    Select,
    Paging,
    Table,
    ImportFile,
    EditContract,
    ImportButton
  },
  mixins: [commodityInfoDict],

  data() {
    return {
      contractDialogVisible: false,
      importDatas: [],
      selectImportData: [],
      file: '',
      importSubmitLoading: false,
      importAdjustDetailsColumns,
      importDialogVisible: false,
      styleList: [],
      sizeList: [],
      colorList: [],
      detailColumns,
      wholeColumns,
      wholePager: {
        current: 1,
        size: 20,
        total: 0
      },
      detailPager: {
        current: 1,
        size: 20,
        total: 0
      },
      activeName: 'first',
      queryForm: { vendorIdList: [], value: [] },
      queryDetailForm: {},
      tableLoading: false,
      optionsConfig: { key: 'val', label: 'label', value: 'val' },
      wholeTable: [],
      detailTable: [],
      multipleSelectionTable: [],
      exportLoading: false
    }
  },
  computed: {
    queryParams() {
      const obj = {}
      if (this.activeName === 'first') {
        const { value = [] } = this.queryForm
        const [startChangePriceLaunchTime, endChangePriceLaunchTime] = value || []
        Object.assign(obj, this.wholePager, omit(this.queryForm, 'value'), { startChangePriceLaunchTime, endChangePriceLaunchTime })
      } else {
        const { styleList } = this.queryDetailForm
        Object.assign(obj, this.detailPager,
          this.queryDetailForm,
          { styleList: this.styleList.find(item => item.id === styleList)?.styleName },)
      }
      return obj
    },
    api() {
      return this.activeName === 'first' ? priceBillPage : priceBillDetailPage
    },
    exportParams() {
      const obj = omit(this.queryParams, ['current', 'size', 'pages', 'total'])
      const idList = []
      if (this.multipleSelectionTable.length) {
        this.multipleSelectionTable.map(item => idList.push(item.id))
        Object.assign(obj, { idList })
      }
      return obj
    }
  },
  created() {
    // 除了登记都返回first Tab页
    const currentTab = JSON.parse(sessionStorage.getItem('currentTab')) || ''
    currentTab ? (currentTab === 'first' ? this.activeName = 'first' : this.activeName = 'second') : this.activeName = 'first'
  },
  mounted() {
    this.handleQuery()
  },
  methods: {
    handleContract() {
      const { billStatus } = this.multipleSelectionTable.length && this.multipleSelectionTable[0]
      if (this.multipleSelectionTable.length === 1 && billStatus === '4') {
        this.contractDialogVisible = true
      } else {
        this.$message.warning('仅能选择一笔调价单操作状态需等于待上传调价协议')
      }
    },
    async submitDetailDatas() {
      try {
        this.importSubmitLoading = true
        const { code, msg } = await changePriceBillImport({ changePriceBillImportRowVoList: this.selectImportData })
        if (code === 0) {
          this.$notify({
            message: msg,
            type: 'success'
          })
          this.importDialogVisible = false
          this.handleQuery()
        }
      } finally {
        this.importSubmitLoading = false
      }
    },
    pagerUpdate(val) {
      this.activeName === 'first' ? this.wholePager = val : this.detailPager = val
      this._queryLists()
    },
    handleQueryFormReset() {
      this.queryForm = this.$options.data().queryForm
      this.queryDetailForm = this.$options.data().queryDetailForm
      this.handleQuery()
    },
    handleTabClick() {
      this.wholePager = this.$options.data().wholePager
      this.detailPager = this.$options.data().detailPager
      this.$refs.multipleTable.$refs.table.clearSelection()
      this.multipleSelectionTable = []
      this.handleQuery()
    },
    handleQuery() {
      this.wholePager.current = 1
      this.detailPager.current = 1
      this._queryLists()
    },
    async _queryLists() {
      try {
        this.tableLoading = true
        const { code, datas: { pager, records = [] }} = await this.api(this.queryParams)
        if (code === 0) {
          if (this.activeName === 'first') {
            this.wholeTable = records.map(item => {
              return { ...item,
                purchaseOrderCode: item.purchaseOrderCode && item.purchaseOrderCode.split(','),
                changePriceAgreementCode: item.changePriceAgreementCode ? item.changePriceAgreementCode.split(',') : ['待编辑变更协议']
              }
            })
            this.wholePager = pager
          } else {
            this.detailTable = records
            this.detailPager = pager
          }
        }
      } finally {
        this.tableLoading = false
        sessionStorage.removeItem('currentTab')
      }
    },
    handleAdd({ changePriceBillCode = '' }, type,) {
      sessionStorage.setItem('currentTab', JSON.stringify(this.activeName))
      this.$router.push({
        path: 'add',
        append: 'true',
        query: { changePriceBillCode, type }})
    },
    async handleExport() {
      const { datas } = await findDownloadUrl('SCM_CHANGE_PRICE_BILL_TEMPLATE')
      downloads(datas)
    },
    handleDelete(row) {
      this.$confirm('确认要作废此调价单吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async() => {
        const { msg } = await cancel(row)
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
        this.handleQuery()
      })
    },
    async uploadFile() {
      try {
        const form = new FormData()
        form.append('file', this.file)
        const { code, datas } = await changePriceBillImportView(form)
        if (code === 0) {
          this.importDatas = datas
          this.$emit('input', false)
          this.importDialogVisible = true
        }
      } finally {
        this.$refs.uploadForm.clearFiles()
      }
    }
  }
}
</script>

<style>

</style>
