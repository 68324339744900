<template>
  <div>
    <el-dialog
      title="调价协议编辑"
      :visible.sync="dialogVisible"
      width="800px"
      center
      :close-on-click-modal="false"
    >
      <Table
        :table-data="tableTable"
        :columns="eidtContractColumns"
        :loading="tableLoading"
      >
        <el-table-column slot="operate" label="操作" align="center" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row,scope.$index)">编辑</el-button>
          </template>
        </el-table-column>
      </Table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="$emit('input',false)">取消</el-button>
        <el-button type="primary" :loading="saveLoading" @click="submit('save')">保存</el-button>
        <el-button type="primary" :loading="submitLoading" @click="submit('submit')">提交</el-button>
      </span>
    </el-dialog>
    <WangEdit v-model="dialogWangEditVisible" :html="textForm.fileRichText" :params="textForm" :index="index" @saveWangEdit="saveWangEdit" />
  </div>
</template>

<script>
import { eidtContractColumns } from '@/constant/tablecolumns'
import { listEditAgreementCode, editAgreement, saveEditAgreementCode, submitEditAgreementCode } from '@/api/adjust-price'
import Table from '@/components/Table'
import WangEdit from './WangEdit'

export default {
  components: { Table, WangEdit },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    changePriceBillCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      saveEditAgreementCode, submitEditAgreementCode,
      dialogWangEditVisible: false,
      saveLoading: false,
      submitLoading: false,
      eidtContractColumns,
      tableTable: [],
      tableLoading: false,
      textForm: { fileRichText: '' },
      index: 0
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    editParams() {
      return Object.assign({}, { changePriceBillCode: this.changePriceBillCode }, { data: this.tableTable })
    }

  },
  watch: {
    'dialogVisible'(val) {
      if (val) {
        this.handleQuery()
      } else {
        this.tableTable = []
      }
    }
  },
  mounted() {
  },
  methods: {
    saveWangEdit({ html, idx, changePriceAgreementCode }) {
      const obj = this.tableTable.find((item, index) => index === idx)
      if (obj) {
        this.$set(obj, 'fileRichText', html)
        this.$set(obj, 'currentHtml', html)
        this.$set(obj, 'changePriceAgreementCode', changePriceAgreementCode)
      }
    },
    handleCancel() {
      this.dialogWangEditVisible = false
    },
    async handleEdit(row, index) {
      this.index = index
      const currentHtml = this.tableTable.find((item, idx) => index === idx)?.currentHtml
      const { purchaseOrderContractCode, changePriceBillCode, purchaseOrderCode, changePriceAgreementCode = '' } = row
      const { datas } = await editAgreement({ purchaseOrderContractCode, changePriceBillCode, purchaseOrderCode, changePriceAgreementCode })
      Object.assign(this.textForm, datas, { fileRichText: currentHtml || datas.fileRichText })
      this.dialogWangEditVisible = true
    },
    async handleQuery() {
      try {
        this.tableLoading = true
        const { datas } = await listEditAgreementCode({ changePriceBillCode: this.changePriceBillCode })
        this.tableTable = datas
      } finally {
        this.tableLoading = false
      }
    },
    async submit(flag) {
      let submitFlag = false
      if (flag === 'submit') {
        submitFlag = this.tableTable.length && this.tableTable.some(item => !item.changePriceAgreementCode)
      }
      if (this.tableTable.length && !submitFlag) {
        try {
          flag === 'save' ? this.saveLoading = true : this.submitLoading = true
          const api = flag === 'save' ? saveEditAgreementCode : submitEditAgreementCode
          const { code } = await api(this.editParams)
          if (code === 0) {
            this.$emit('input', false)
            this.$emit('refresh')
          }
          // console.log(this.editParams)
        } finally {
          this.saveLoading = false
          this.submitLoading = false
        }
      } else {
        this.$message.warning('请确保所有合同都有编辑且保存过')
      }
    }
  }
}
</script>

<style scoped lang="scss">
/deep/.el-input  .el-input__inner {
    width: 100%!important;
}
</style>
